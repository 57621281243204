
// The main container element
.Collapsible {
  background-color: white;
  cursor: pointer;
  width: 70vw;;
}

.customContainer {
  border: 4px solid #22AE96;
  border-radius: 1em;
  align-content: center;
  width:97vw !important;
  height: 99vh;
  overflow: unset;
}

.formContainer {
  align-content: center;
  overflow:auto;
  height: 70vh !important;
}


.form-label {
  font-weight: bold !important;
}

.form-check-label {
  cursor: pointer !important;
}



//The content within the collaspable area
.Collapsible__contentInner {
  padding: 10px;
  cursor: initial !important;
  p {
    margin-bottom: 10px;
    font-size: 2vw;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: grey;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #C89605;
  border-radius:2em;
  color: white;
  margin-bottom: 2em;
  text-align: center;
  font-size:1.4vw;
  height: 3vw;
  line-height: 1.5vw;

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}


.btn-custom {
  background-color: #ADC805 !important;
  color:white !important;
}

.btn-lg {
  border-radius: 1em !important;
  font-size:1.4vw !important;
  height:3vw !important;
  line-height: 1.5vw !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h3 {
  font-size:1.5vw !important;
  position: relative !important;
  top: 15% !important;
}

.input-row {
  margin-right: 4vw !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #22AE96;
  border-radius: 10px;
}

::-webkit-scrollbar {
  border-radius: 10px;
}