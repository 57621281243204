.bottomContainer {
    min-height : 10vh;
    position:sticky;
  }

  
.progress {
  border-radius:1rem !important;
  font-size:1rem !important;
  height:1.5rem !important;
  text-align: center !important;
}

.progress-bar {
  background-color: #3FC400!important;
}

