.round {
    position: relative;
  }
  
  .round label {
    background-color: #C0DFCD;
    border: 1px solid #C0DFCD;
    border-radius: 50%;
    height: 3vw;
    left: 0;
    position: absolute;
    top: 0;
    width: 3vw;
  }
  
  .round label:after {
    border: 4px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 1vw;
    left: 0.8vw;
    opacity: 0;
    position: absolute;
    top: 0.8vw;
    transform: rotate(-45deg);
    width: 1.4vw;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #ADC805;
    border-color: #ADC805;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }